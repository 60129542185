import { GetServerSidePropsContext } from 'next';

/* --------------------------------------------------------------------------
 * NOTE: this function is used to fetch a session without using the next-auth
 * client.
 *
 * This is meant to be used only in the context of deploy previews, where we
 * cannot dynamically set the NEXTAUTH_URL env variable that the next-auth
 * client depends on.
 *
 * The function is largely copied from the next-auth client source:
 *   https://github.com/nextauthjs/next-auth/blob/90a6a0084b01a624c76a891886ab1b37e551a2ad/src/client/index.js#L161-L167
 * ------------------------------------------------------*/

const isKnownHost = (host: string, siteName: string) =>
  host.match(`^deploy-preview-\\d+--${siteName}\\.netlify\\.app$`) ||
  host.match(`^\\w+--${siteName}\\.netlify\\.app$`) ||
  host.match(`^guide\\.flatironschool\\.com$`);

export const fetchSession = async ({
  req: {
    headers: { cookie, host },
  },
}: GetServerSidePropsContext) => {
  const siteName = process.env.SITE_NAME as string;

  try {
    console.log(`FETCH_SESSION_HOST ${host}`);

    if (!isKnownHost(host as string, siteName))
      throw new Error(`Unknown host in fetchSession: "${host}"`);

    const options = { headers: { cookie: cookie as string } };
    const url = `https://${host}/api/auth/session`;
    const res = await fetch(url, options);
    const data = await res.json();

    if (!res.ok) throw data;

    return data;
  } catch (error) {
    console.error('FETCH_SESSION_ERROR', host, siteName, error);
    return null;
  }
};
