// NOTE: in order for this helper to work in functions, we must declare an env
// variable for the site in the UI/settings of DEPLOY_PREVIEW_CONTEXT = deploy-preview
//
// Additionally, something in this process seems to convert "deploy-preview" to "deploy_preview",
// so we're checking for both values here to cover both possibilities.
export const isDeployPreview = () =>
  process.env.CONTEXT === 'deploy_preview' ||
  process.env.CONTEXT === 'deploy-preview';

export const isProduction = () =>
  (!!process.env.NETLIFY && process.env.CONTEXT === 'production') ||
  process.env.NODE_ENV === 'production';

// Manages the oddities of context-specific environment variables
// on Netlify Functions.  Netlify Functions don't honor environment
// variables updated by netlify-contextual-env, so we have to do it.
export const getContextualEnv = (key: string): string | undefined => {
  const envPrefix = process.env.CONTEXT
    ? `${process.env.CONTEXT.toUpperCase()}_`
    : '';

  return process.env[`${envPrefix}${key}`] ?? process.env[key];
};

export const portalEndpoint = () => {
  const endpoint =
    process.env.NEXT_PUBLIC_PORTAL_ENDPOINT || process.env.PORTAL_ENDPOINT;

  if (!endpoint) {
    throw new Error('Portal endpoint is not set!');
  }

  return endpoint;
};
