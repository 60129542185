import { getSession, useSession } from 'next-auth/client';
import { GetServerSidePropsContext } from 'next';
import { Session } from 'next-auth';
import Cookies from 'js-cookie';

import { isDeployPreview } from '@fis/util-environment';

import type { Permissions, Token } from './types';
import { fetchSession } from './fetch-session';

export const activeImpersonation = () => !!Cookies.get('fis-impersonation');

export const getCurrentUser = async (context: GetServerSidePropsContext) => {
  let session;

  if (isDeployPreview()) {
    console.warn(
      'GET_CURRENT_USER Deploy preview detected; fetching session without next-auth/client'
    );

    session = await fetchSession(context);
  } else {
    session = await getSession(context);
  }

  return session ? new CurrentUser(session as Token) : null;
};

export const useCurrentUser = (): [null | CurrentUser, boolean] => {
  const [session, loading] = useSession();

  return session
    ? [new CurrentUser(session as Token), loading]
    : [null, loading];
};

export class CurrentUser {
  permissions: Permissions;
  token: Token;
  uuid: string;

  constructor(token: Token) {
    this.token = token;

    this.permissions = token.permissions;
    this.uuid = token.sub;
  }

  isEducator = () => this.permissions.includes('educator');
  isAdmin = () => this.permissions.includes('admin');
  isSuperAdmin = () => this.permissions.includes('super_admin');

  isImpersonating = () => activeImpersonation();
}

export default {
  activeImpersonation,
  CurrentUser,
  useCurrentUser,
};
